<template>
    <main class="container">
        <div class="content">
            <div class="section-heading">
                <h1>Privacy and Cookies</h1>
            </div>
            <div v-html="store.privacy" class="widget-box">
            </div>
        </div>
        <aside class="sidebar">
        </aside>
    </main>
</template>
<script>

export default {
    name: 'PrivacyAndCookies',
    metaInfo() {
        if(this.$store.state.siteSetting){

          let ss = this.$store.state.siteSetting;

          let title = this.$getSafe(() => ss.privacy_meta_title);
          let description = this.$getSafe(() => ss.privacy_meta_description);
          let keywords = this.$getSafe(() => ss.privacy_meta_keywords);

          return {
            title: title,
            description: description,
            meta: this.$getMetaTags(title, description,keywords),
            link:[
              {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
            ],
          }
        }
    },
    data() {
        return {
          store: {},
        }
    },
    mounted() {
        setTimeout(() => {
            this.store = this.$store.state.siteSetting;
        },1000);
    },
    methods: {

    }
}

</script>
